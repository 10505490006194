import { useCheckStringLength } from '/machinery/useCheckStringLength'
import { useGetBoundingClientRect } from '/machinery/useGetBoundingClientRect'

import { EnterAnimation } from '/features/buildingBlocks/EnterAnimation'
import { ContainerXl } from '/features/buildingBlocks/Container'
import { MediaWindow } from '/features/pageOnly/MediaWindow'
import { LinePageHero } from '/features/pageOnly/Line'

import styles from './HeroPage.css'

export function HeroPage({ title, introduction, heroImage, heroImageSecondary, abbreviation = undefined, layoutClassName = undefined }) {
  return (
    <section data-x='hero' className={cx(styles.component, layoutClassName)}>
      <ContainerXl>
        <Hero {...{ title, introduction, heroImage, heroImageSecondary, abbreviation }} />
      </ContainerXl>
    </section>
  )
}

function Hero({ title, introduction, heroImage, heroImageSecondary, abbreviation }) {
  const isLongTitle = useCheckStringLength({ strings: [title], allowedLength: 10 })

  return (
    <div className={styles.componentHero}>

      <div className={styles.headingAndIntroContainer}>
        <EnterAnimation offset={0}>
          <h1 className={cx(styles.heading, isLongTitle && styles.headingLongTitle)}>
            {title}
          </h1>
        </EnterAnimation>

        {abbreviation && <p className={styles.titleAbbreviation}>{abbreviation}</p>}

        <LineAndIntro layoutClassName={styles.lineAndIntroLayout} {...{ introduction }} />
      </div>

      <div className={styles.headingImages}>
        {heroImage && (
          <EnterAnimation  delay={350} offset={0}>
            <MediaWindow image={heroImage} aspectRatio={2 / 4} distanceOverride={60} />
          </EnterAnimation>
        )}
        {heroImageSecondary && (
          <EnterAnimation  delay={350} offset={0}>
            <MediaWindow image={heroImageSecondary} aspectRatio={2 / 4} distanceOverride={80} />
          </EnterAnimation>
        )}
      </div>

    </div>
  )
}

function LineAndIntro({ introduction, layoutClassName = undefined }) {
  const { height: introHeight, elementRef } = useGetBoundingClientRect()
  const lineHeight = introHeight / 1.5

  return (
    <div className={cx(styles.componentLineAndIntro, layoutClassName)}>
      <LinePageHero {...{ lineHeight }} />

      <EnterAnimation delay={250} offset={0}>
        <div ref={elementRef} className={styles.introContainer}>
          {introduction && <p className={styles.introduction}>{introduction}</p>}
        </div>
      </EnterAnimation>
    </div>
  )
}
