import { HeroPage } from '/features/pageOnly/HeroPage'
import { ContentWrapper } from '/features/buildingBlocks/ContentWrapper'
import { ContainerMd } from '/features/buildingBlocks/Container'
import { LargeIntroTextCentered } from '/features/pageOnly/LargeIntroText'
import { ContentWithoutUniversalComponents } from '/features/buildingBlocks/ContentWithoutUniversalComponents'

import styles from './XxlCampaignSubpageApp.css'

export default function XxlCampaignSubpageApp({
  title,
  introduction,
  heroImage,
  heroImageSecondary,
  introText,
  content,
}) {
  return (
    <div className={styles.component}>
      <HeroPage {...{ title, introduction, heroImage, heroImageSecondary }} />

      <ContentWrapper>
        {introText && (
          <ContainerMd>
            <LargeIntroTextCentered value={introText} />
          </ContainerMd>
        )}

        {content && <ContentWithoutUniversalComponents {...{ content }} />}
      </ContentWrapper>
    </div>
  )
}
